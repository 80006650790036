import React, { useEffect } from 'react';
import Countdown from 'react-countdown';
import './index.scss';

import { paddingTwoDigits } from 'utils/convert';

interface Props {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const Time = (props: any) => {
  const renderer: React.FC<Props> = ({ days, hours, minutes, seconds }) => {
    return (
          <div className='clock-number'>{paddingTwoDigits(days)} : {paddingTwoDigits(hours)} : {paddingTwoDigits(minutes)} : {paddingTwoDigits(seconds)}</div>
    );
  };

  const { leftTimestamp } = props;
  useEffect(() => {
    // console.log('leftTimestamp', leftTimestamp);
  }, [leftTimestamp]);

  const deadline = leftTimestamp || Date.now() + 60000;

  return leftTimestamp ? (
    <Countdown date={deadline} renderer={renderer} />
  ) : (
    <div></div>
  );
};

export default Time;
